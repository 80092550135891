import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
      <footer className="bg-white shadow dark:bg-gray-900  border-t-gray-200 border">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <Link
              to="/"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
              <img
                src={require('../../assets/logo2.png')}
                className="h-28"
                alt="Flowbite Logo"
              />
            </Link>
            <ul className="flex flex-wrap justify-center items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link
                  to="/refund_policy"
                  className="hover:underline me-4 md:me-6  font-Onest">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy_policy"
                  className="hover:underline me-4 md:me-6 font-Onest">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  className="hover:underline me-4 md:me-6 font-Onest">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400 font-Onest">
            © 2024{' '}
            <a href="/" className="hover:underline font-Onest">
              Runanubandh Vishwa Vivah
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
