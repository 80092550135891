import React from 'react';
import Information from './Information';
import { UserCircleIcon, UserGroupIcon } from '@heroicons/react/20/solid';

import Header from '../../../src/AdminPanel/components/common/Header';
import { useGetDashboardQuery } from '../../redux/services/admin/api';
import Spinner from '../../components/common/ChangeLanguage/Spinner';

const Adminportal = () => {
  const { data, isLoading } = useGetDashboardQuery({});

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="antialiased bg-gray-50 dark:bg-gray-900">
      <Header />

      <main className="sm:p-20  p-2 h-auto pt-20">
        <div className="grid grid-cols-2 gap-4 mb-4 mt-10 ">
          <div className="border-2 border-solid flex justify-center items-center rounded-lg font-Onest border-gray-200 dark:border-gray-600 h-48 md:h-72">
            <Information
              title="Total Users"
              User={data?.data?.totalUsers}
              icon={
                <UserGroupIcon
                  className="sm:h-32 sm:w-32 h-9 w-9"
                  color="red"
                />
              }
            />
          </div>
          <div className="border-2 border-solid flex justify-center items-center rounded-lg font-Onest border-gray-200 dark:border-gray-600 h-48 md:h-72">
            <Information
              title="Today's New User"
              User={data?.data?.totalUsersToday}
              icon={
                <UserCircleIcon
                  className="sm:h-24 sm:w-24 h-9 w-9"
                  color="yellow"
                />
              }
            />
          </div>
          <div className="border-2 border-solid flex justify-center items-center rounded-lg font-Onest border-gray-200 dark:border-gray-600 h-48 md:h-72">
            <Information
              title="Total Income"
              User={data?.data?.totalIncome}
              icon={
                <UserCircleIcon
                  className="sm:h-24 sm:w-24 h-9 w-9"
                  color="yellow"
                />
              }
            />
          </div>
          <div className="border-2 border-solid flex justify-center items-center rounded-lg font-Onest border-gray-200 dark:border-gray-600 h-48 md:h-72">
            <Information
              title="Today's Total Income"
              User={data?.data?.totalIncomeToday}
              icon={
                <UserCircleIcon
                  className="sm:h-24 sm:w-24 h-9 w-9"
                  color="black"
                />
              }
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Adminportal;
