import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';

export type FilterProps = {
  onSelect: (
    selectedAge: string | null,
    selectedIncomeRange: string | null,
  ) => void;
  onCancel: () => void;
};

export function Filter({ onSelect, onCancel }: FilterProps) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedAge, setSelectedAge] = useState<string | null>(null);
  const [selectedIncomeRange, setSelectedIncomeRange] = useState<string | null>(
    null,
  );

  const ageRanges = ['18-23', '23-27', '27-31', '31-50'];
  const incomeRanges = [
    { label: '1L-5L', value: '100000-500000' },
    { label: '5L-8L', value: '500000-800000' },
    { label: '8L-13L', value: '800000-1300000' },
    { label: '13L-1CR', value: '1300000-10000000' },
  ];

  const handleCancel = () => {
    onCancel();
    setSelectedAge(null);
    setSelectedIncomeRange(null);
    setOpenModal(false);
  };

  return (
    <>
      <div className="flex flex-row gap-2 h-10 items-center ml-2">
        <div onClick={() => setOpenModal(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
            />
          </svg>
        </div>
        {(selectedAge || selectedIncomeRange) && (
          <span className="text-red-500" onClick={handleCancel}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </span>
        )}
      </div>

      <Modal show={openModal} size="lg" onClose={handleCancel} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="">
            <h1 className="font-Onest  font-bold mb-5">Apply Filter</h1>
            <h1 className="font-Onest mb-2 text-sm">Select Age Range</h1>

            <div className="grid gap-5 sm:grid-cols-4 grid-cols-2 ">
              {ageRanges?.map((item, index) => {
                const isSelected = selectedAge === item;
                const borderColor = isSelected
                  ? 'border-red-500'
                  : 'border-gray-200';
                const bgColor = isSelected ? 'bg-red-100' : 'bg-white';
                const textColor = isSelected ? 'text-red-900' : 'text-gray-900';

                return (
                  <div
                    onClick={() => setSelectedAge(item)}
                    className={`flex items-center h-10 gap-2 justify-center ${bgColor}  border ${borderColor} rounded `}>
                    <label
                      className={`font-Onest text-sm font-medium  ${textColor}`}>
                      {item}
                    </label>
                  </div>
                );
              })}
            </div>
            <h1 className="font-Onest mt-4 text-sm">Select Income Range</h1>
            <div className="grid gap-5 sm:grid-cols-4 grid-cols-2 mt-2">
              {incomeRanges?.map((item, index) => {
                const isSelected = selectedIncomeRange === item.value;
                const borderColor = isSelected
                  ? 'border-red-500'
                  : 'border-gray-200';
                const bgColor = isSelected ? 'bg-red-100' : 'bg-white';
                const textColor = isSelected ? 'text-red-900' : 'text-gray-900';

                return (
                  <div
                    onClick={() => setSelectedIncomeRange(item.value)}
                    className={`flex items-center h-10 gap-2 justify-center ${bgColor}  border ${borderColor} rounded `}>
                    <label
                      className={`font-Onest text-sm font-medium  ${textColor}`}>
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-center gap-4 mt-6">
              <Button
                color="failure"
                onClick={() => {
                  onSelect(selectedAge, selectedIncomeRange);
                  setOpenModal(false);
                }}
                className="font-Onest">
                {"Yes, I'm sure"}
              </Button>
              <Button
                color="gray"
                onClick={handleCancel}
                className="font-Onest">
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
