import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import { useUpdateUserMutation } from '../../../redux/services/admin/api';
import { showToast } from '../../../utils/ErrorToast';
interface propState {
  userData: any;
}

const UserDetailsScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  let { userData } = (location?.state as propState) ?? { userData: null };

  const userInfo = [
    {
      Name: 'Full Name',
      data: userData?.userDetails?.personalDetails?.fullName ?? '',
    },
    {
      Name: 'Date Of Birth',
      data: userData?.userDetails?.personalDetails?.dateOfBirth ?? '',
    },
    {
      Name: 'Time Of Birth',
      data: userData?.userDetails?.personalDetails?.timeOfBirth ?? '',
    },
    {
      Name: 'Birth Place',
      data: userData?.userDetails?.personalDetails?.bornPlace ?? '',
    },
    {
      Name: 'Marriage Status',
      data: userData?.userDetails?.personalDetails?.marriageStatus ?? '',
    },
    {
      Name: 'Gender',
      data: userData?.userDetails?.personalDetails?.gender ?? '',
    },
    {
      Name: 'Blood Group',
      data: userData?.userDetails?.personalDetails?.bloodGroup ?? '',
    },
    {
      Name: 'Religion',
      data: userData?.userDetails?.personalDetails?.religion ?? '',
    },
    {
      Name: 'Caste',
      data: userData?.userDetails?.personalDetails?.caste ?? '',
    },
    {
      Name: 'Sub Caste',
      data: userData?.userDetails?.personalDetails?.subCaste ?? '',
    },
    {
      Name: 'Education',
      data: userData?.userDetails?.basicDetails?.education ?? '',
    },
    {
      Name: 'Occupation',
      data: userData?.userDetails?.basicDetails?.occupation ?? '',
    },
    {
      Name: 'Income Per Year',
      data: userData?.userDetails?.basicDetails?.income ?? '',
    },
    {
      Name: 'Height (in feet)',
      data: userData?.userDetails?.basicDetails?.height ?? '',
    },
    {
      Name: 'Weight (in kg)',
      data: userData?.userDetails?.basicDetails?.weight ?? '',
    },
    {
      Name: 'Skin Color',
      data: userData?.userDetails?.basicDetails?.skinColor ?? '',
    },
    {
      Name: 'Mother Full Name',
      data: userData?.userDetails?.familyDetails?.motherName ?? '',
    },
    {
      Name: 'Mother Mobile Number',
      data: userData?.userDetails?.familyDetails?.motherMobileNumber ?? '',
    },
    {
      Name: 'Father Full Name',
      data: userData?.userDetails?.familyDetails?.fatherName ?? '',
    },
    {
      Name: 'Father Mobile Number',
      data: userData?.userDetails?.familyDetails?.fatherMobileNumber ?? '',
    },
    {
      Name: 'No Of Brothers',
      data: userData?.userDetails?.familyDetails?.numOfBrothers ?? '',
    },
    {
      Name: 'No Of Sisters',
      data: userData?.userDetails?.familyDetails?.numOfSisters ?? '',
    },
    {
      Name: 'Address',
      data: userData?.userDetails?.addressDetails?.address ?? '',
    },
    {
      Name: 'Temp Address',
      data: userData?.userDetails?.addressDetails?.tempAddress ?? '',
    },
    {
      Name: 'City',
      data: userData?.userDetails?.addressDetails?.city ?? '',
    },
    {
      Name: 'PinCode',
      data: userData?.userDetails?.addressDetails?.pincode ?? '',
    },
    {
      Name: 'Taluka',
      data: userData?.userDetails?.addressDetails?.taluka ?? '',
    },
    {
      Name: 'District',
      data: userData?.userDetails?.addressDetails?.district ?? '',
    },
    {
      Name: 'State',
      data: userData?.userDetails?.addressDetails?.state ?? '',
    },
  ];

  const handleSuccess = () => {
    showToast('User Updated Successfully', 'success');
    navigate(-1);
  };

  const handleButton = (status: string) => {
    updateUser({
      mobileNumber: userData?.mobileNumber,
      status,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(err => showToast('Something went wrong', 'error'));
  };

  return (
    <>
      <Header />

      <div className="grid sm:grid-rows-1 sm:px-36 sm:ml-36 sm:py-12 mt-20 p-2 rounded-lg h-full ">
        <div className="sm:flex   justify-center items-center py-6 overflow-x-auto border-t-2 border-l-2 border-r-2 border-solid rounded-md ">
          <img
            src={userData?.photoDetails?.profilePhoto}
            className="rounded-2xl h-96 object-cover p-2 "
            alt="not found"
          />
          <div className="">
            {userData?.photoDetails?.adharCard?.includes('.pdf') ? (
              <a href={userData?.photoDetails?.adharCard} target="blank">
                <img
                  src={require('../../../assets/open-pdf.png')}
                  className="rounded-lg h-96"
                  alt="not found"
                />
              </a>
            ) : (
              <img
                src={userData?.photoDetails?.adharCard}
                className="rounded-lg"
                alt="not found"
              />
            )}
          </div>
        </div>
        <div className="flex  justify-center items-center  border-b-2 border-l-2 border-r-2 border-solid sm:rounded-s-lg">
          <table className="text-sm   text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <tbody>
              {userInfo.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                      <div className="ps-3">
                        <div className="text-sm font-medium font-Onest">
                          {item.Name}
                        </div>
                      </div>
                    </th>
                    <td className="px-6 py-4 font-Onest">{item.data}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className=" grid sm:grid-cols-2 sm:px-36 sm:ml-36 sm:py-12 mt-2">
        <button
          type="button"
          onClick={() => handleButton('ACTIVE')}
          className="text-white bg-green-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Approve
        </button>
        <button
          type="button"
          onClick={() => handleButton('REJECT')}
          className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
          Reject
        </button>
      </div>
    </>
  );
};

export default UserDetailsScreen;
