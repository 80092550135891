import {
  CalendarIcon,
  CurrencyRupeeIcon,
  EnvelopeIcon,
  FlagIcon,
  MapPinIcon,
  PhoneArrowDownLeftIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/20/solid";
import { Button, Modal } from "flowbite-react";
import moment from "moment";
import { useState } from "react";


const ProfileName = ({ userData }: any) => {

  const { firstName, lastName, middleName, dateOfBirth } = userData?.userDetails
    ?.personalDetails ?? { firstName: "", lastName: "", middleName: "" };
  const fullName = `${firstName} ${middleName} ${lastName}`;

  const { city } = userData?.userDetails.addressDetails;
  const { income } = userData?.userDetails.basicDetails;

  return (
    <>
      <div className="border-b border-gray-900/10  border border-gray-200 border-r-2 p-6 rounded-b-lg">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight font-Onest ">
              {fullName}
            </h2>

            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500 font-Onest">
                <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400 " />
                {city}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 font-Onest">
                <CurrencyRupeeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {Intl.NumberFormat("en-In", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 0,
                }).format(income)}

                <span className="pl-1">(Per year)</span>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 font-Onest">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {moment(dateOfBirth).format("dddd, DD MMM YYYY")}
              </div>
            </div>
          </div>
          <div className="mt-5 flex lg:ml-4 lg:mt-10">
            <span className=" sm:block">
              <a href={`mailto:${userData?.email}`}>
                <button
                  type="button"
                  className="inline-flex items-center font-Onest rounded-md bg-blue-50 px-3 py-2 text-sm font-semibold text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 hover:bg-red-50"
                >
                  <EnvelopeIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                  Mail
                </button>
              </a>
            </span>

            <span className="ml-3  sm:block">
              <a href={`tel:${userData?.mobileNumber}`}>
                <button
                  type="button"
                  className="inline-flex items-center font-Onest text-red-500 rounded-md bg-red-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-50"
                >
                  <PhoneArrowDownLeftIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  Call Now
                </button>
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileName;
