import React, { useState } from 'react';

import { useFormik } from 'formik';
import { registerSchema } from '../../utils/RegisterYup';
import {
  useDoLoginMutation,
  useDoRegisterMutation,
  useMakePaymentMutation,
} from '../../redux/services/register/api';
import Login from './Login';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../utils/ErrorToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { skipPayment } from '../../config/constant';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  Mobile: '',
  Email: '',
  Password: '',
};

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('Login');

  const [doRegister, { isLoading: isRegisterLoading }] =
    useDoRegisterMutation();
  const [doLogin] = useDoLoginMutation();

  const [makePayment, { isLoading: isPaymentLoading }] =
    useMakePaymentMutation();
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: registerSchema,
      onSubmit: values => {
        doRegister({
          email: values?.Email,
          mobileNumber: values?.Mobile,
          password: values?.Password,
        })
          .unwrap()
          .then(res => {
            if (res?.data && res?.data?.token && res?.data?.id) {
              //make payment after registration
              if (skipPayment) {
                doLogin({
                  mobileNumber: values?.Mobile,
                  password: values?.Password,
                })
                  .unwrap()
                  .then(res => {
                    if (res?.success) {
                      localStorage.setItem('token', res?.data?.token ?? '');
                      navigate('/home', { replace: true });
                    } else {
                      showToast('Incorrect Mobile Number or Password', 'error');
                    }
                  })
                  .catch(() => {
                    showToast('Something went wrong', 'error');
                  });
              } else {
                let data = {
                  number: values?.Mobile,
                  MID: 'MID' + Date.now(),
                  transactionId: 'T' + Date.now(),
                  userId: res?.data?.id,
                };

                makePayment(data)
                  .unwrap()
                  .then(res => {
                    if (res.success === true) {
                      window.location.href =
                        res.data.instrumentResponse.redirectInfo.url;
                    }
                  })
                  .catch(error => showToast('Something went wrong', 'error'));
              }
            } else {
              setIsLoading(false);
              showToast(
                'Mobile number already exist please try with login',
                'error',
              );
            }
          })
          .catch(() => {
            setIsLoading(false);
            showToast('Something went wrong', 'error');
          });
      },
    });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className="h-screen">
      <div className="grid sm:grid-cols-2 grid-cols-1 px-44 pt-12">
        <div className="grid-cols-1  hidden lg:flex">
          <img
            src={
              'https://whitethread.s3.amazonaws.com/matrimony/profile/78efs.webp'
            }
            className="h-[593px] w-full box-content object-cover border-gray-200 border rounded-tl-lg rounded-bl-lg"
            alt="ash"
          />
        </div>
        <div className="grid-cols-1 bg-white h-[593px] sm:border-gray-100 sm:rounded-sm sm:border-r-2 sm:border-t-2 sm:border-b-2">
          <div className="flex justify-center mt-4">
            <span
              onClick={() => setSelectedTab('Login')}
              className={`cursor-pointer text-${
                selectedTab === 'Login' ? 'red' : 'gray'
              }-500  font-Onest  font-${
                selectedTab === 'Login' ? 'bold' : 'medium'
              } text-sm px-5 py-2 text-center  mr-1 ${
                selectedTab === 'Login' && 'underline'
              } underline-offset-8`}>
              {t('registerDetails.login.title')}
            </span>
            <span
              onClick={() => setSelectedTab('Signup')}
              className={`cursor-pointer text-${
                selectedTab === 'Signup' ? 'red' : 'gray'
              }-500  font-Onest  font-${
                selectedTab === 'Signup' ? 'bold' : 'medium'
              } text-sm px-5 py-2 text-center  mr-1 ${
                selectedTab === 'Signup' && 'underline'
              } underline-offset-8`}>
              {t('registerDetails.login.signUp')}
            </span>
          </div>
          {selectedTab === 'Login' ? (
            <Login onSignupClick={() => setSelectedTab('Signup')} />
          ) : (
            <div className=" flex flex-col justify-center w-full">
              <div className="relative ">
                <form onSubmit={handleSubmit}>
                  <div className="relative flex justify-center   bg-white ">
                    <div className="border border-red-100 rounded-3xl mt-12 px-10 py-4">
                      <div>
                        <h1 className="text-2xl font-semibold font-Onest justify-center flex ">
                          {t('registerDetails.register.title')}
                        </h1>
                      </div>
                      <div className="divide-x divide-gray-200 mt-2">
                        <div className="text-base leading-6 space-y-5 text-gray-700  ">
                          <div className="sm:col-span-3 ">
                            <label
                              htmlFor="Mobile"
                              className="block text-sm font-medium leading-6 text-gray-900 font-Onest">
                              {t('registerDetails.register.mobile')}
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                maxLength={10}
                                name="Mobile"
                                id="Mobile"
                                autoComplete="off"
                                className="block w-64 outline-none font-Onest sm:w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={values.Mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.Mobile && touched.Mobile ? (
                                <p className="text-red-500 font-Onest text-xs mt-1">
                                  {errors.Mobile}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:col-span-2 ">
                            <label
                              htmlFor="Email"
                              className="block text-sm font-medium leading-6 text-gray-900 font-Onest">
                              {t('registerDetails.register.email')}
                            </label>
                            <div className="mt-1">
                              <input
                                type="email"
                                name="Email"
                                id="Email"
                                autoComplete="off"
                                className="block w-64 font-Onest sm:w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={values.Email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.Email && touched.Email ? (
                                <p className="text-red-500 font-Onest text-xs mt-1">
                                  {errors.Email}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:col-span-2 ">
                            <label
                              htmlFor="Password"
                              className="block text-sm font-medium leading-6 text-gray-900 font-Onest">
                              {t('registerDetails.register.password')}
                            </label>
                            <div className="mt-1 flex">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                name="Password"
                                id="Password"
                                autoComplete="off"
                                className="block  w-64 font-Onest sm:w-72 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={values.Password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div className="relative top-2 pb-1 h-6  right-9  justify-center items-center  rounded-sm flex-row gap-1 ">
                                <FontAwesomeIcon
                                  className="text-gray-400"
                                  icon={showPassword ? faEyeSlash : faEye}
                                  onClick={() => setShowPassword(prev => !prev)}
                                />
                              </div>
                            </div>
                            {errors.Password && touched.Password ? (
                              <p className="text-red-500 font-Onest text-xs mt-1">
                                {errors.Password}
                              </p>
                            ) : null}
                          </div>
                          <div className="sm:col-span-2 flex justify-center">
                            <span className="font-Onest text-sm ">
                              {t('registerDetails.register.charges')}
                            </span>
                          </div>
                          <div className="relative pt-2">
                            <button
                              type="submit"
                              disabled={
                                isLoading ||
                                isPaymentLoading ||
                                isRegisterLoading
                              }
                              className="bg-red-500 w-64 text-white rounded-md sm:w-72 py-2 font-Onest font-bold flex content-center justify-center">
                              {isLoading ||
                              isPaymentLoading ||
                              isRegisterLoading ? (
                                <div className="flex flex-row justify-center items-center gap-2">
                                  <svg
                                    aria-hidden="true"
                                    className="w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span className="font-Onest text-sm">
                                    {' '}
                                    Loading
                                  </span>
                                </div>
                              ) : (
                                'Register and Pay'
                              )}
                            </button>
                          </div>
                          {/* <div className="">
                            <div className=" text-gray-400 w-full font-Onest text-xs text-center">
                              Already have an account?{' '}
                              <span className=" text-black w-full font-Onest text-xs  text-center underline underline-offset-4">
                                Login here
                              </span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
