import { useNavigate } from 'react-router-dom';

// import Script from "dangerous-html/react";
// import { Helmet } from "react-helmet";

import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  EyeIcon,
  HeartIcon,
  ShieldCheckIcon,
  SwatchIcon,
} from '@heroicons/react/20/solid';
import Footer from '../components/common/Footer';
import './home.css';
// import "./style.css";

const Home = props => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex min-h-screen  items-center flex-col">
      <section className="w-full h-screen flex items-center flex-col">
        <div className="flex-1 w-full flex relative items-center sm:px-20 flex-col">
          <div className="w-full h-full  flex overflow-hidden absolute items-center flex-col justify-center">
            <video
              src
              poster="https://whitethread.s3.ap-south-1.amazonaws.com/matrimony/bKlO2JL9SrCt2UTdTl5nSA.jpeg"
              className="w-full h-full object-cover"></video>
            <div className="top-0 left-0 right-0 bottom-0 w-full h-full m-auto flex absolute items-center flex-col bg-black/40"></div>
          </div>

          <div className="flex-1 w-full flex items-center flex-col">
            <header
              data-thq="thq-navbar"
              className="w-full flex relative  items-center sm:py-12 py-6 justify-between">
              <img alt="logo" src="/logo2.png" className="sm:h-[83px] h-16" />
            </header>
            <div className="gap-12 w-full h-full flex z-[100]  items-center flex-col justify-center mt-12 ">
              <div className="sm:gap-6 w-full flex items-center flex-col justify-center">
                <h1 className="text-white sm:text-5xl text-2xl font-bold  text-center  font-Onest">
                  Your Journey to Love Begins Here
                </h1>
                <p className="text-white sm:text-base text-xs  text-center font-karla  font-Onest sm:p-0">
                  Join our matrimony app to find your perfect match. Love is
                  just a click away.
                </p>
                <button
                  onClick={() => navigate('/auth')}
                  color="light"
                  className="bg-red-500 hover:bg-red-300 text-white font-bold py-2 p-16 rounded-full font-Onest mt-6 text-xl">
                 Click here for new Registration
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id="features"
          className="w-full  z-50 items-center sm:py-[var(--dl-space-space-threeunits)] sm:px-[var(--dl-space-space-fiveunits)] bg-red-500 justify-center">
          <div className="sm:gap-20 w-full   items-start flex-row grid sm:grid-cols-3 grid-cols-1 p-3">
            <div className="w-full  flex  flex-col items-start sm:mt-0 mt-2">
              <div className="flex items-center flex-row md">
                <HeartIcon className="h-8 sm:h-16" color="white" />
                <h3 className="text-white text-xl font-bold font-Onest ml-2">
                  Advanced Matchmaking Algorithm
                </h3>
              </div>
              <p className="text-white text-base font-Onest leading-custom sm:ml-0 ml-10">
                Utilizes AI and machine learning to provide personalized and
                compatible match suggestions based on user preferences and
                background
              </p>
            </div>
            <div className="w-full  flex  flex-col items-start sm:mt-0 mt-5 ">
              <div className="flex items-center flex-row">
                <ShieldCheckIcon className="h-8 sm:h-16" color="white" />
                <h3 className="text-white text-xl font-bold font-Onest  ml-2">
                  Verified Profiles
                </h3>
              </div>
              <p className="text-white text-base font-Onest leading-custom sm:ml-0 ml-10">
                Ensures profile authenticity through a thorough verification
                process, creating a safe and trustworthy environment.
              </p>
            </div>
            <div className="w-full  flex  flex-col items-start sm:mt-0 mt-5">
              <div className="flex items-center flex-row">
                <SwatchIcon className="h-8 sm:h-16" color="white" />
                <h3 className="text-white text-xl font-bold font-Onest  ml-2">
                  Comprehensive Search Filters
                </h3>
              </div>
              <p className="text-white text-base font-Onest leading-custom  sm:ml-0 ml-10">
                Offers detailed search filters based on age, religion, caste,
                profession, location, and more for refined and efficient
                matchmaking.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="how-it-works"
        className=" w-full flex  items-center    flex-col">
        <div className="w-full  items-center justify-start sm:mt-10 sm:p-20">
          <div className="grid sm:grid-cols-2 items-start justify-start">
            <div className=" grid-cols-1 items-end justify-center">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1587271315307-eaebc181c749?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="object-cover sm:w-full sm:max-h-[500px] rounded-lg sm:p-0 p-7 "
              />
            </div>
            <div className="grid-cols-1 items-start justify-center w-full sm:max-w-[500px] sm:ml-20 sm:mt-20 sm:p-0 px-5 ">
              <h2 className="sm:text-5xl text-2xl font-Onest font-bold">
                Safety & Security
              </h2>
              <p className="text-gray-500 text-lg max-w-custom text-left leading-custom font-Onest mt-5">
                Your safety is our top priority. At runanubandhvishwavivah.com,
                we use the latest security measures and profile verification
                processes to ensure a secure and trustworthy environment for our
                users. Browse and connect with confidence ...
              </p>
            </div>
          </div>
        </div>
        <hr class="w-48 h-1 mx-auto mt-10 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
        <div className="w-full  items-center justify-start sm:mt-10 sm:p-20 mt-10">
          <div className="grid sm:grid-cols-2 items-start justify-start">
            <div className="grid-cols-1 items-start justify-center w-full sm:max-w-[500px] sm:ml-20 sm:mt-20 sm:p-0 px-5 ">
              <h2 className="sm:text-5xl text-2xl font-Onest font-bold">
                Our Mission
              </h2>
              <p className="text-gray-500 text-lg max-w-custom text-left leading-custom font-Onest mt-5">
                At runanubandhvishwavivah.com, we are dedicated to creating
                genuine connections. Our goal is to help you find true love and
                companionship through a safe, user-friendly platform with
                verified profiles and advanced matching algorithms
              </p>
            </div>
            <div className=" grid-cols-1 items-end justify-center">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1613665667184-81bb9b8605e2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="object-cover sm:w-full sm:max-h-[500px]  rounded-lg sm:p-0 p-7"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <footer className="bg-red-500 w-full sm:p-12 p-3">
        <div className="container p-6 mx-auto">
          <div className="lg:flex">
            <div className="w-full -mx-6 lg:w-2/5">
              <div className="px-6">
                <a href="#">
                  <img
                    alt="logo"
                    src="/logo2.png"
                    className="sm:h-[63px] h-14"
                  />
                </a>

                <p className="max-w-sm mt-2 text-white dark:text-gray-400 font-Onest">
                  Join 31,000+ other and never miss out on new tips, tutorials,
                  and more.
                </p>
              </div>
            </div>

            <div className="mt-6 lg:mt-0 lg:flex-1">
              <div className="grid grid-cols-2 gap-6  sm:grid-cols-2 ">
                <div>
                  <h3 className="text-white uppercase dark:text-white font-Onest">
                    About
                  </h3>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    Company
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    community
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    Careers
                  </a>
                </div>

                <div>
                  <h3 className="text-white uppercase dark:text-white font-Onest">
                    About
                  </h3>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    Company
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    community
                  </a>
                  <a
                    href="#"
                    className="block mt-2 text-sm text-white dark:text-gray-400 hover:underline font-Onest"
                  >
                    Careers
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
