import React from 'react';
import Header from '../components/Header';
import Footer from '../components/common/Footer';
import { suport } from '../config/constant';

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Header isUserFullyOnboarded={false} />

      <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        {' '}
        <strong>
          <h1>Privacy Policy</h1>{' '}
        </strong>
        <br />
        <p>
          {' '}
          Welcome to <strong>Runanubandh Vishwavivah</strong>! We value your
          privacy and are committed to protecting your personal data. This
          Privacy Policy explains how we collect, use, and safeguard your
          information when you use our platform.{' '}
        </p>{' '}
        <br />
        <h2>1. Information We Collect</h2>{' '}
        <p>We collect the following types of information from users:</p>{' '}
        <ul>
          {' '}
          <li>
            <strong>Personal Information:</strong> Your name, age, gender,
            marital status, email address, phone number, and other relevant
            details provided during registration.
          </li>{' '}
          <li>
            <strong>Profile Information:</strong> Information you provide about
            yourself in your profile such as your educational background, job
            details, and preferences for a life partner.
          </li>{' '}
          <li>
            <strong>Usage Data:</strong> Data about how you interact with the
            platform, including your browsing activities, searches, and
            preferences.
          </li>{' '}
        </ul>{' '}
        <br />
        <h2>2. How We Use Your Information</h2>{' '}
        <p>We use the information collected for the following purposes:</p>{' '}
        <ul>
          {' '}
          <li>
            To help you connect with potential matches on the platform.
          </li>{' '}
          <li>
            To provide personalized recommendations based on your preferences.
          </li>{' '}
          <li>To improve and enhance the platform's features and services.</li>{' '}
          <li>
            To communicate with you regarding platform updates, services, and
            promotions.
          </li>{' '}
          <li>
            To ensure security, prevent fraud, and protect the integrity of our
            platform.
          </li>{' '}
        </ul>{' '}
        <br />
        <h2>3. Sharing Your Information</h2>{' '}
        <p>
          {' '}
          We respect your privacy and will not share, sell, or rent your
          personal information to third parties, except under the following
          circumstances:{' '}
        </p>{' '}
        <ul>
          {' '}
          <li>
            With your consent, to facilitate potential matches on the platform.
          </li>{' '}
          <li>
            To comply with legal obligations or respond to lawful requests by
            government authorities.
          </li>{' '}
          <li>
            To enforce our Terms and Conditions, or protect our rights, privacy,
            and safety.
          </li>{' '}
        </ul>{' '}
        <br />
        <h2>4. Data Security</h2>{' '}
        <p>
          {' '}
          We use industry-standard measures to protect your personal information
          from unauthorized access, disclosure, or misuse. However, no method of
          transmission over the internet is 100% secure, and we cannot guarantee
          absolute security.{' '}
        </p>{' '}
        <br />
        <h2>5. Your Rights</h2> <p> As a user, you have the right to: </p>{' '}
        <ul>
          {' '}
          <li>
            Access and update your personal information at any time through your
            account settings.
          </li>{' '}
          <li>
            Request the deletion of your personal data, subject to certain
            exceptions.
          </li>{' '}
          <li>
            Withdraw consent for the processing of your data by deleting your
            account.
          </li>{' '}
        </ul>{' '}
        <br />
        <h2>6. Cookies and Tracking Technologies</h2>{' '}
        <p>
          {' '}
          We use cookies and similar tracking technologies to enhance your
          experience on our platform. You can control your cookie preferences
          through your browser settings.{' '}
        </p>{' '}
        <br />
        <h2>7. Changes to This Policy</h2>{' '}
        <p>
          {' '}
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. Any updates will be posted on this page, and we encourage you
          to review the policy periodically.{' '}
        </p>{' '}
        <br />
        <h2>8. Contact Us</h2>{' '}
        <p>
          {' '}
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at <strong>
            {suport.email}
          </strong>.{' '}
        </p>{' '}
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;
