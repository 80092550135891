import React from 'react';
import Header from '../components/Header';
import Footer from '../components/common/Footer';
import { suport } from '../config/constant';
import MarqueeSider from '../components/common/MarqueeSider';

const ContactScreen = () => {
  return (
    <>
      <Header isUserFullyOnboarded={true} />
      <MarqueeSider />
      <section className="py-24">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="md:flex gap-x-24 clear-left md:mb-16 mb-10">
            <div className=" md:mb-0 mb-4">
              <h2 className="text-black font-manrope text-4xl font-semibold leading-10 mb-5 md:text-left text-center font-Onest">
                Get In Touch
              </h2>
              <p className="text-gray-600 text-lg font-normal leading-7 mb-7 md:text-left text-center font-Onest">
                "We specialize in eco-friendly home goods that not only improve
                your living space but also reduce your environmental footprint.
                From biodegradable cleaning products to sustainable kitchenware,
                each item is designed with both quality and sustainability in
                mind."
              </p>
              <div className="flex md:items-center md:justify-start justify-center">
                <button className="w-36 h-12 rounded-full bg-red-500 shadow text-white text-center text-base font-semibold leading-6">
                  Contact Us
                </button>
              </div>
            </div>
            <div className="border-l-2 md:border-red-600 border-white px-10 py-6">
              <div className="mb-8">
                <h6 className="text-gray-500 text-sm font-medium leading-5 pb-3 md:text-start text-center">
                  Email Address
                </h6>
                <h3 className="text-gray-600 text-base font-semibold leading-8 md:text-start text-center font-Onest">
                  {suport.email}
                </h3>
              </div>
              <div>
                <h6 className="text-gray-500 text-sm font-medium leading-5 pb-3 md:text-start text-center">
                  Phone Number
                </h6>
                <h3 className="text-gray-600 text-base font-semibold leading-8 md:text-start text-center font-Onest">
                  {suport.mobile}
                </h3>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
            <div className="h-96 relative flex justify-center">
              <div className="w-full h-full absolute bg-gradient-to-t from-gray-800/50 to-gray-600/50"></div>
              <img
                src="https://images.unsplash.com/photo-1587271315307-eaebc181c749?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="United Kingdom image"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="h-96 relative flex justify-center">
              <div className="w-full h-full absolute bg-gradient-to-t from-gray-800/50 to-gray-600/50"></div>
              <img
                src="https://images.unsplash.com/photo-1514120649397-daec977c71b5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Germany image"
                className="w-full h-full  object-cover"
              />
            </div>
            <div className="h-96 relative flex justify-center">
              <div className="w-full h-full absolute bg-gradient-to-t from-gray-800/50 to-gray-600/50"></div>
              <img
                src="https://media.istockphoto.com/id/635831548/photo/kanyadaan-ritual-in-a-maharashtrian-wedding-in-india.jpg?s=2048x2048&w=is&k=20&c=_noGYUve9qP0W2zUuiRy92e38Oj6MLD_R0S5WgXKlII="
                alt="France image"
                className="w-full h-full  object-cover"
              />
            </div>
            <div className="h-96 relative flex justify-center">
              <div className="w-full h-full absolute bg-gradient-to-t from-gray-800/50 to-gray-600/50"></div>
              <img
                src="https://images.pexels.com/photos/1444442/pexels-photo-1444442.jpeg"
                alt="United Kingdom image"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactScreen;
