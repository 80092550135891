import { CurrencyRupeeIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

function ProfileInfo({ data }: any) {
  const { t } = useTranslation();
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    timeOfBirth,
    bornPlace,
    marriageStatus,
    gender,
    bloodGroup,
    caste,
    religion,
  } = data?.personalDetails;
  const {
    education,
    occupation,
    income,
    height,
    weight,
    rashi,
    nakshatra,
    skinColor,
  } = data?.basicDetails;
  const {
    motherName,
    motherMobileNumber,
    fatherName,
    fatherMobileNumber,
    brotherName,
    brotherMobileNumber,
    sisterName,
    sisterMobileNumber,
  } = data?.familyDetails;

  const { address, city, district, pincode, state, taluka, tempAddress } =
    data?.addressDetails;

  const {
    PPAnnualIncome,
    PPCaste,
    PPEducation,
    PPResidingCountry,
    PPResidingState,
    PPResidingCity,
    PPHeight,
    PPWeight,
    PPSkinColor,
  } = data?.partnerpreferences ?? "";

  return (
    <div className="border-b border-gray-900/10 mb-10 border border-gray-200 border-r-2 sm:p-8 p-6 rounded-xl mt-4">
      {/* personal info */}
      <div>
        <h2 className="text-base  leading-7 text-gray-900 font-Onest font-semibold">
          {t("userDetails.personalInfo.title")}
        </h2>

        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-y-8 sm:grid-cols-6 ">
          <div className="sm:col-span-2 col-span-1 ">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.firstName")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{firstName}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1 ">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.middleName")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{middleName}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.lastName")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{lastName}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.dateOfBirth")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">
                {moment(dateOfBirth).format("dddd, DD MMM YYYY")}
              </span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.timeOfBirth")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">
                {moment(timeOfBirth, ["HH:mm"]).format("hh:mm a")}
              </span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.birthPlace")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{bornPlace}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.marriageStatus")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{marriageStatus}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.gender")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{gender}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.bloodGroup")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{bloodGroup}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.caste")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{caste}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Birth Place"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.personalInfo.fields.religion")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{religion}</span>
            </div>
          </div>
        </div>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* basic Info */}
      <div className="mt-10">
        <h2 className="text-base font-bold leading-7 text-gray-900 font-Onest">
          {t("userDetails.basicInfo.title")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 font-Onest"></p>

        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Education"
              className="block text-xs font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.basicInfo.fields.education")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{education}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Occupation"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.basicInfo.fields.occupation")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{occupation}</span>
            </div>
          </div>

          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Income"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.basicInfo.fields.income")}
            </label>
            <div className="mt-1 flex">
              <CurrencyRupeeIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span className="font-Onest ">
                {Intl.NumberFormat("en-In", {
                  style: "currency",
                  currency: "INR",
                  maximumFractionDigits: 0,
                }).format(income)}
              </span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Height"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.basicInfo.fields.height")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{height}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Weight"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.basicInfo.fields.weight")}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{weight}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Weight"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Skin Color"}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{skinColor ?? "NA"}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Weight"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Rashi"}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{rashi ?? "NA"}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Weight"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Nakshatra"}
            </label>
            <div className="mt-2">
              <span className="font-Onest ">{nakshatra ?? "NA"}</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      {/* family Info */}
      <div className="mt-10">
        <h2 className="text-base font-bold leading-7 text-gray-900 font-Onest">
          {t("userDetails.familyInfo.title")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 font-Onest"></p>

        <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="MotherName"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.motherName")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{motherName}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="MotherMobileNumber"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.motherMobileNo")}
            </label>
            <div className="mt-2">
              <a href={`tel:${motherMobileNumber}`}>
                <span className="font-Onest hover:underline">
                  {motherMobileNumber}
                </span>
              </a>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="FatherName"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.fatherName")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{fatherName}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="FatherMobileNumber"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.fatherMobileNo")}
            </label>
            <div className="mt-2">
              <a href={`tel:${fatherMobileNumber}`}>
                <span className="font-Onest hover:underline">
                  {fatherMobileNumber}
                </span>
              </a>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="BrotherName"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.brotherName")}
            </label>
            <div className="mt-2">
              {brotherName && brotherName ? (
                <span className="font-Onest">{brotherName}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>

          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="SisterName"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.familyInfo.fields.sisterName")}
            </label>
            <div className="mt-2">
              {sisterName && sisterName ? (
                <span className="font-Onest">{sisterName}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* address Info */}
      <div className="mt-10">
        <h2 className="text-base font-bold leading-7 text-gray-900 font-Onest">
          {t("userDetails.addressInfo.title")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 font-Onest"></p>

        <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="Address"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.address")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{address}</span>
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="TempAddress"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.tempAddress")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{tempAddress}</span>
            </div>
          </div>

          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="city"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.city")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{city}</span>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="Pincode"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.pinCode")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{pincode}</span>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="taluka"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.taluka")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{taluka}</span>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="district"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.district")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{district}</span>
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="state"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {t("userDetails.addressInfo.fields.state")}
            </label>
            <div className="mt-2">
              <span className="font-Onest">{state}</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

      {/* Expectations from partner */}
      <div className="mt-10">
        <h2 className="text-base font-bold leading-7 text-gray-900 font-Onest">
          {"Partner Preferences"}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 font-Onest"></p>

        <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor="    {'Annual Income'}"
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Annual Income"}
            </label>
            <div className="mt-2">
              {PPAnnualIncome && PPAnnualIncome ? (
                <span className="font-Onest">{PPAnnualIncome}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"Caste"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Caste"}
            </label>
            <div className="mt-2">
              {PPCaste && PPCaste ? (
                <span className="font-Onest">{PPCaste}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"Education"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Education"}
            </label>
            <div className="mt-2">
              {PPEducation && PPEducation ? (
                <span className="font-Onest">{PPEducation}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"ResidingCountry"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Residing Country"}
            </label>
            <div className="mt-2">
              {PPResidingCountry && PPResidingCountry ? (
                <span className="font-Onest">{PPResidingCountry}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"ResidingState"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Residing State"}
            </label>
            <div className="mt-2">
              {PPResidingState && PPResidingState ? (
                <span className="font-Onest">{PPResidingState}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"ResidingCity"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Residing City"}
            </label>
            <div className="mt-2">
              {PPResidingCity && PPResidingCity ? (
                <span className="font-Onest">{PPResidingCity}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"Height"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Height"}
            </label>
            <div className="mt-2">
              {PPHeight && PPHeight ? (
                <span className="font-Onest">{PPHeight}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"Weight"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Weight"}
            </label>
            <div className="mt-2">
              {PPWeight && PPWeight ? (
                <span className="font-Onest">{PPWeight}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2 col-span-1">
            <label
              htmlFor={"SkinColor"}
              className="block text-sm font-medium leading-6 text-gray-500 font-Onest"
            >
              {"Skin Color"}
            </label>
            <div className="mt-2">
              {PPSkinColor && PPSkinColor ? (
                <span className="font-Onest">{PPSkinColor ?? "NA"}</span>
              ) : (
                <span className="font-Onest">NA</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;
