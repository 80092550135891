import React, { useEffect, useState } from "react";
import Header from "../Header";
import ProfileName from "./ProfileName";
import ProfileInfo from "./ProfileInfo";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import { FlagIcon } from "@heroicons/react/20/solid";
import { Button, Modal } from "flowbite-react";
import { useReportUserProfileMutation } from "../../redux/services/userInfo/api";
import { showToast } from "../../utils/ErrorToast";

interface propState {
  userData: any;
}

export const ProfileDetailsCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState('');
  const [reportProfile, {isLoading}]=useReportUserProfileMutation()

  let { userData } = (location?.state as propState) ?? { userData: null };

  useEffect(() => {
    if (!userData) {
      navigate("/home", { replace: true });
    }
  }, []);

  const handleSubmit = () => {
    reportProfile({
      description,
      reportedUserId: userData._id,
      reportingUserId: "1",
    })
      .unwrap()
      .then((res) =>{
        if(res.success){
          showToast('Reported successfully', 'success');
        }
      })
      .catch((err) =>  showToast('Something went wrong', 'error'));
  };

  const openReportModal = () => {
    setOpenModal(true);
  };


  return (
    <>
      <Header isUserFullyOnboarded={true} />
      <div className="w-full px-3 flex items-center justify-center ">
        <div className="grid overflow-hidden lg:grid-cols-1  grid-cols-1 lg:w-6/12  ">
          {/* 1 */}
          <div className="box  lg:min-h-[600px] mt-2">
            <div className="rounded  relative ">
              <img
                className="rounded-t-lg w-full max-h-[600px]  sm:object-fill object-cover"
                src={userData.photoDetails.profilePhoto}
                alt="not found"
              />
              <div className="top-5 absolute  h-6 px-2 right-2  justify-center items-center flex rounded-sm flex-row gap-1 ">
                <span
                  className={`text-4xl h-8 w-8 bg-white justify-center flex  items-center rounded-full  content-center text-red-300 cursor-pointer`}
                >
                  <FlagIcon
                    className="h-4 text-red-500"
                    onClick={openReportModal}
                  />
                </span>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="">
            <ProfileName userData={userData} />
          </div>
          <div className="">
            <ProfileInfo data={userData?.userDetails} />
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="">
            <h3 className="mb-3 text-lg text-center  text-gray-500 dark:text-gray-600 font-Onest font-bold">
              Report User Profile
            </h3>
            <label className="font-Onest">
              Write Description:
              <textarea
                name="postContent"
                rows={4}
                cols={40}
                color="gray"
                onChange={(e) => setDescription(e.target.value)}
                className="font-Onest mt-3 border-gray-200"
              />
            </label>
            <div className="flex justify-center gap-4">
              <Button disabled={isLoading} className="bg-red-500 font-Onest" onClick={handleSubmit}>
                {"Submit"}
              </Button>
              <Button
                color="gray"
                className="font-Onest"
                onClick={() => setOpenModal(false)}
              >
                cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileDetailsCard;
