import { Dropdown } from 'flowbite-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteUserMutation,
  useGetProfilesQuery,
} from '../../redux/services/admin/api';
import { showToast } from '../../utils/ErrorToast';
import { useFormik } from 'formik';
import { SearchSchema } from '../../utils/SearchSchema';
import Header from './common/Header';

const initialValues = {
  fullName: '',
};

const UserListScreen = ({ status }: { status: 'ACTIVE' | 'PENDING' }) => {
  const [page, setPage] = useState(0);
  const [fullName, setFullName] = useState<string | null>(null);
  const [deleteUser] = useDeleteUserMutation();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetProfilesQuery({
    page,
    status,
    fullName: fullName,
  });
  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: SearchSchema,
    onSubmit: values => {
      setFullName(values?.fullName);
    },
  });

  const handleDeleteUser = (userId: string) => {
    deleteUser({ userId })
      .unwrap()
      .then(res => {
        if (res?.success) {
          showToast('User Deleted Successfully', 'success');
        } else {
          showToast('Something went wrong', 'error');
        }
      })
      .catch(err => {
        showToast('Something went wrong', 'error');
      });
  };

  return (
    <>
      <Header />

      <section className="bg-gray-50 dark:bg-gray-900    mt-20 px-4 ">
        <div className="mx-auto max-w-screen-2xl sm:p-12   ">
          {/* className Start coding here --> */}
          <div className="bg-white dark:bg-gray-800  relative shadow-md sm:rounded-lg  overflow-hidden ">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <div className="sm:w-3/6  ">
                <form className="flex items-center" onSubmit={handleSubmit}>
                  <label className="sr-only">Search</label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor">
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      name="fullName"
                      className="bg-gray-50 border font-Onest  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search"
                      value={values.fullName}
                      onChange={handleChange}
                      onBlur={e => {
                        handleBlur(e);
                        if (values?.fullName === '') {
                          setFullName(null);
                        }
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-white ml-2 mt-1 bg-red-500 hover:bg-red-800  font-Onest font-medium rounded-full text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                    Search
                  </button>
                </form>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <div className="flex items-center space-x-3 w-full md:w-auto">
                  <button
                    id="filterDropdownButton"
                    data-dropdown-toggle="filterDropdown"
                    className="w-full md:w-auto flex items-center justify-center py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4 mr-2 text-gray-400"
                      fill="currentColor">
                      <path
                        fill-rule="evenodd"
                        d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    {/* Filter */}
                    <svg
                      className="-mr-1 ml-1.5 w-5 h-5"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true">
                      <path
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      />
                    </svg>
                  </button>
                  <div
                    id="filterDropdown"
                    className="z-10 hidden w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                    <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                      Choose brand
                    </h6>
                    <ul
                      className="space-y-2 text-sm"
                      aria-labelledby="filterDropdownButton">
                      <li className="flex items-center">
                        <input
                          id="apple"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                          Apple (56)
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="fitbit"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                          Microsoft (16)
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="razor"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                          Razor (49)
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="nikon"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                          Nikon (12)
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="benq"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                          BenQ (74)
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-4 py-3 font-Onest">Name</th>
                    <th scope="col" className="px-0 py-3 font-Onest">
                      Date of Birth
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Marriage Status
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Gender
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Education
                    </th>
                    <th scope="col" className="px-0 py-3 font-Onest">
                      Occupation
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Income
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Height
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      Action
                    </th>
                    <th scope="col" className="px-4 py-3 font-Onest">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                {isFetching || isLoading ? (
                  <h1>Loading</h1>
                ) : (
                  <tbody>
                    {data?.data?.userData?.map((item: any, index: number) => {
                      return (
                        <tr key={index} className="border-b  hover:bg-green-50">
                          <div
                            className="flex items-center ml-6 hover:cursor-pointer"
                            onClick={() =>
                              navigate('/admin/userInfo', {
                                state: { userData: item },
                              })
                            }>
                            <img
                              className="w-8 h-8 rounded-full"
                              src={item?.photoDetails?.profilePhoto ?? ''}
                              alt="Bonnie Green avatar"
                            />

                            <th
                              scope="row"
                              className="px-4 py-3 font-medium text-gray-900 font-Onest whitespace-nowrap dark:text-white">
                              {item?.userDetails?.personalDetails?.fullName ??
                                ''}
                            </th>
                          </div>
                          <td className="px-0 py-3 font-Onest">
                            {item?.userDetails?.personalDetails?.dateOfBirth ??
                              ''}
                          </td>
                          <td className="px-4 py-3 font-Onest">
                            {item?.userDetails?.personalDetails
                              ?.marriageStatus ?? ''}
                          </td>
                          <td className="max-w-16 py-3 font-Onest">
                            {item?.userDetails?.personalDetails?.gender ?? ''}
                          </td>
                          <td className="px-4 py-3 font-Onest">
                            {item?.userDetails?.basicDetails?.education ?? 'NA'}
                          </td>
                          <td className="px-4 py-3 font-Onest">
                            {item?.userDetails?.basicDetails?.occupation ??
                              'NA'}
                          </td>
                          <td className="px-4 py-3 font-Onest">
                            {item?.userDetails?.basicDetails?.income ?? 'NA'}
                          </td>
                          <td className="px-4 py-3 font-Onest">
                            {item?.userDetails?.basicDetails?.height ?? 'NA'}
                          </td>
                          <td className="px-4 py-3 font-Onest flex items-center justify-end">
                            <Dropdown
                              inline
                              className="bg-white"
                              label=""
                              dismissOnClick={false}
                              renderTrigger={() => (
                                <button
                                  type="button"
                                  className="hs-dropdown-toggle py-3 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg   bg-white text-gray-800  hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                                  <svg
                                    className="w-5 h-5"
                                    aria-hidden="false"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                                  </svg>
                                </button>
                              )}>
                              <Dropdown.Item
                                className="font-Onest"
                                onClick={() =>
                                  navigate('/admin/userInfo', {
                                    state: { userData: item },
                                  })
                                }>
                                View Profile
                              </Dropdown.Item>
                              <Dropdown.Item className="font-Onest">
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font-Onest"
                                onClick={() => handleDeleteUser(item._id)}>
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item className="font-Onest">
                                Sign out
                              </Dropdown.Item>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <div className="flex justify-center m-5 flex-row">
                {page > 0 && (
                  <button
                    type="button"
                    onClick={() => setPage(prev => prev - 1)}
                    className="text-white font-Onest bg-green-700   font-medium rounded-sm text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    See Page {page - 1}
                  </button>
                )}
                {data?.data?.userData?.length >= 10 && (
                  <button
                    type="button"
                    onClick={() => setPage(prev => prev + 1)}
                    className="text-white font-Onest bg-green-700   font-medium rounded-sm text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    See Page {page + 1}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserListScreen;
