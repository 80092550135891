import React from 'react';
import Header from '../components/Header';
import Footer from '../components/common/Footer';

function AboutScreen() {
  return (
    <>
      <Header isUserFullyOnboarded={true} />
      <div className="sm:flex items-center sm:p-14 ">
        <div className="sm:w-1/2 p-5">
          <div className="image  text-center  ">
            <img
              src="https://plus.unsplash.com/premium_photo-1682092592909-8d26686e7f5e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="rounded-lg"
              alt=""
            />
          </div>
        </div>
        <div className="sm:w-1/2 p-5  ">
          <div className="text">
            <h2 className="my-4 font-bold text-3xl  sm:text-4xl font-Onest">
              About <span className="text-red-500">Our Company</span>
            </h2>
            <p className="text-gray-700 font-Onest">
              "We specialize in eco-friendly home goods that not only improve
              your living space but also reduce your environmental footprint.
              From biodegradable cleaning products to sustainable kitchenware,
              each item is designed with both quality and sustainability in
              mind."
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutScreen;
