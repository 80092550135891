import React from 'react';
import { AdminInfo } from './types';

const Information = ({ title, User, icon }: AdminInfo) => {
  return (
    <div className="w-full  sm:mt-5 ">
      <div className="flex justify-center sm:mt-10">{icon}</div>
      <div className="flex items-center justify-center sm:mt-3">
        <h3 className="text-black text-xl font-bold font-Onest  ml-2">
          {User}
        </h3>
      </div>
      <div className="flex justify-center">
        <p className="text-gray-400 sm:text-lg text-base font-semibold font-Onest leading-custom sm:ml-0   sm:mt-5 mt-2">
          {title}
        </p>
      </div>
    </div>
  );
};

export default Information;
