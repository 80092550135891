import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ProfileCardProps } from './types';

const ProfileCard = ({
  name,
  photo,
  dob,
  city,
  occupation,
  caste,
  onClick,
  handleAddToFevorite,
}: ProfileCardProps) => {
  return (
    <div
      onClick={onClick}
      className="group relative rounded border-slate-200 border overflow-hidden  duration-300 sm:w-72 w-80 ">
      <div className="relative">
        <img
          src={photo}
          alt=""
          className="object-cover h-56 sm:w-72 w-80 hover:scale-105 transition-all   duration-500 cursor-pointer overflow-hidden"
        />
        {dob && (
          <div className="absolute top-2  h-6  px-2 left-2 bg-yellow-50 justify-center items-center flex rounded-sm flex-row gap-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-3  text-yellow-400">
              <path
                fillRule="evenodd"
                d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                clipRule="evenodd"
              />
            </svg>

            <h1 className="font-bold text-[11px] text-yellow-800 font-Onest">
              {moment().diff(dob, 'years')}
            </h1>
          </div>
        )}
        {handleAddToFevorite && (
          <div className="absolute bottom-2  h-6 px-2 right-2  justify-center items-center flex rounded-sm flex-row gap-1 ">
            <span
              onClick={e => {
                if (handleAddToFevorite) {
                  e.stopPropagation();
                  handleAddToFevorite();
                }
              }}
              className={`text-4xl h-8 w-8 bg-white justify-center flex  items-center rounded-full  content-center text-red-300 cursor-pointer`}>
              <FontAwesomeIcon className="h-4 text-red-500" icon={solidHeart} />
            </span>
          </div>
        )}
      </div>

      <div className="p-4">
        <span className=" text-md lg:text-sm font-semibold font-Onest text-slate-800">
          {name}
        </span>

        <div className="mt-3">
          <div className="flex justify-between mb-2">
            <span className="font-semibold font-Onest text-slate-500  text-xs">
              Occupation
            </span>
            <span className="text-slate-400 font-Onest text-xs w-1/2 text-right">
              {occupation.slice(0, 30)}
            </span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="font-semibold font-Onest text-slate-500  text-xs">
              Age
            </span>
            <span className="text-slate-400 font-Onest text-xs">
              {moment().diff(dob, 'years')} years
            </span>
          </div>
          {caste && (
            <div className="flex justify-between mb-2">
              <span className="font-semibold font-Onest text-slate-500  text-xs">
                Caste
              </span>
              <span className="text-slate-400 font-Onest text-xs">{caste}</span>
            </div>
          )}
          <ul className="flex list-none justify-between mb-2">
            <li className=" text-slate-500 font-semibold font-Onest text-xs">
              <span>Address</span>
            </li>
            <li className="text-slate-400 font-medium font-Onest text-xs">
              <span> {city}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
