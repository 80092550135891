import {
  Avatar,
  Drawer,
  Dropdown,
  Navbar,
  Sidebar,
  TextInput,
} from 'flowbite-react';
import { useState } from 'react';
import { HiChartPie, HiSearch, HiShoppingBag, HiUsers } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setIsOpen(false);

  const navigateToUserList = () => {
    handleClose();
    navigate('/admin/userList');
  };

  const navigateToPendingUserList = () => {
    handleClose();
    navigate('/admin/pendingUsers');
  };

  const navigateHome = () => {
    handleClose();
    navigate('/admin/home');
  };
  return (
    <>
      <nav className="bg-white border-b border-gray-100 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            <button
              onClick={() => setIsOpen(prev => !prev)}
              data-drawer-target="drawer-navigation"
              data-drawer-toggle="drawer-navigation"
              aria-controls="drawer-navigation"
              className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer  hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <svg
                aria-hidden="true"
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"></path>
              </svg>
              <span className="sr-only">Toggle sidebar</span>
            </button>
            <Link
              to="/admin/home"
              className="flex items-center justify-between mr-4">
              <img
                src={require('../../../assets/logo2.png')}
                className=" h-12 sm:h-16"
                alt="Flowbite Logo"
              />
            </Link>
          </div>
          <div className="flex items-center lg:order-2">
            {/* <!-- Dropdown menu --> */}

            <div className="flex md:order-2">
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <Avatar
                    alt="User settings"
                    img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                    rounded
                  />
                }>
                <Dropdown.Header>
                  <span className="block text-sm">Admin</span>
                </Dropdown.Header>

                <Dropdown.Divider />
                <Dropdown.Item>Sign out</Dropdown.Item>
              </Dropdown>
            </div>
            {/* <!-- Dropdown menu --> */}
          </div>
        </div>
      </nav>

      <Drawer open={isOpen} onClose={handleClose} className="sm:mt-20 mt-14">
        <Drawer.Header title="MENU" titleIcon={() => <></>} />
        <Drawer.Items>
          <Sidebar
            aria-label="Sidebar with multi-level dropdown example"
            className="[&>div]:bg-transparent [&>div]:p-0">
            <div className="flex h-full flex-col justify-between py-2 font-Onest">
              <div>
                <Sidebar.Items>
                  <Sidebar.ItemGroup>
                    <Sidebar.Item onClick={navigateHome} icon={HiChartPie}>
                      Dashboard
                    </Sidebar.Item>
                    <Sidebar.Item
                      onClick={navigateToUserList}
                      icon={HiShoppingBag}>
                      All Users
                    </Sidebar.Item>
                    <Sidebar.Item
                      onClick={navigateToPendingUserList}
                      icon={HiUsers}>
                      Pending Users
                    </Sidebar.Item>
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </div>
            </div>
          </Sidebar>
        </Drawer.Items>
      </Drawer>
    </>
  );
}

export default Header;
