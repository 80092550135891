import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { Filter } from '../components/HomeDetails/Filter/Filter';
import ProfileCard from '../components/HomeDetails/ProfileCard/ProfileCard';
import Spinner from '../components/common/ChangeLanguage/Spinner';
import Footer from '../components/common/Footer';
import NotFound from '../components/common/NotFound';
import {
  useGetProfilesQuery,
  useGetUserInfoQuery,
} from '../redux/services/userInfo/api';
import { showToast } from '../utils/ErrorToast';
import { SearchSchema } from '../utils/SearchSchema';
import MarqueeSider from '../components/common/MarqueeSider';

const initialValues = {
  fullName: '',
};

export default function HomeScreen() {
  const { data, isLoading } = useGetUserInfoQuery({});
  const [fullName, setFullName] = useState<string | null>(null);
  const [selectedAge, setSelectedAge] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [selectedIncomeRange, setSelectedIncomeRange] = useState<string | null>(
    null,
  );
  const [skip, setSkip] = useState(true);

  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: SearchSchema,
    onSubmit: values => {
      setFullName(values?.fullName);
    },
  });

  const {
    data: profileData,
    isLoading: isProfileListLoading,
    isFetching,
  } = useGetProfilesQuery(
    {
      fullName: fullName,
      selectedAge,
      selectedIncomeRange,
      page,
    },
    { skip },
  );

  const navigate = useNavigate();

  if (data?.redirectTo) {
    navigate(`/${data?.redirectTo}`, {
      replace: true,
    });
  }

  useEffect(() => {
    setInterval(() => {
      setSkip(false);
    }, 1000);
  });

  if (!data?.redirectTo) {
    const fullName = `${data?.data?.userDetails?.personalDetails?.firstName} ${data?.data?.userDetails?.personalDetails?.lastName}`;
    const email = `${data?.data?.email}`;
    const photo = `${data?.data?.photoDetails?.profilePhoto}`;

    localStorage.setItem('name', fullName);
    localStorage.setItem('email', email);
    localStorage.setItem('photo', photo);
    localStorage.setItem('userDetails', JSON.stringify(data?.data));
  }

  const handleNavigation = (item: any) => {
    navigate('/details', { state: { userData: item } });
  };

  const setData = async (item: any) => {
    const LocalStorageKey = 'fevorite'; // Replace with your actual key

    const data = localStorage.getItem(LocalStorageKey);
    let newArray = [];

    if (data) {
      const respData = JSON.parse(data);
      const isAlreadyAdded = respData.filter(
        (resp: { _id: any }) => resp._id === item._id,
      );

      if (isAlreadyAdded.length >= 1) {
        showToast('Already added in favorites', 'error');
      } else {
        newArray = JSON.parse(data);
        newArray.push(item);
        localStorage.setItem(LocalStorageKey, JSON.stringify(newArray));
        showToast('Added to Fervorite', 'success');
      }
    } else {
      newArray.push(item);
      localStorage.setItem(LocalStorageKey, JSON.stringify(newArray));
      showToast('Added to Fervorite', 'success');
    }
  };

  return (
    <>
      <Header isUserFullyOnboarded={true} />
      <MarqueeSider />
      <form onSubmit={handleSubmit}>
        <div className="flex sm:justify-end justify-center sm:mx-36 p-1 mt-2">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              name="fullName"
              id="default-search"
              className="block sm:w-96 w-80 p-3 ps-10 text-sm font-Onest text-gray-900 border border-gray-300 rounded-lg bg-gray-50     dark:placeholder-gray-400 dark:text-white ring-red-500 "
              placeholder="Search Profile"
              required
              value={values.fullName}
              onChange={handleChange}
              onBlur={e => {
                handleBlur(e);
                if (values?.fullName === '') {
                  setFullName(null);
                }
              }}
            />
            <button
              type="submit"
              className="text-white absolute end-2.5 sm:bottom-1 bottom-2.5 bg-red-500 hover:bg-red-800 font-Onest  font-medium rounded-lg text-sm sm:px-4 px-2 sm:py-2 py-1 ">
              Search
            </button>
          </div>
          <Filter
            onSelect={(selectedAge, selectedIncomeRange) => {
              setSelectedAge(selectedAge);
              setSelectedIncomeRange(selectedIncomeRange);
            }}
            onCancel={() => {
              setSelectedAge(null);
              setSelectedIncomeRange(null);
            }}
          />
        </div>
      </form>
      {isLoading || isProfileListLoading || isFetching ? (
        <Spinner />
      ) : profileData?.data?.count === 0 ? (
        <NotFound
          image={require('../assets/Not Found.png')}
          label="User Not Exist"
        />
      ) : (
        <>
          <div className="flex justify-center">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px] lg:px-36 sm:p-10 p-5 items-center justify-center">
              {profileData?.data?.userData?.map((item: any, index: number) => {
                const fullName = `${item?.userDetails?.personalDetails?.firstName} ${item?.userDetails?.personalDetails?.middleName} ${item?.userDetails?.personalDetails?.lastName}`;
                return (
                  <ProfileCard
                    key={item?.id}
                    dob={item?.userDetails?.personalDetails?.dateOfBirth}
                    city={item?.userDetails?.addressDetails?.city}
                    occupation={item?.userDetails?.basicDetails?.occupation}
                    caste={item?.userDetails?.personalDetails?.caste}
                    photo={
                      item?.photoDetails?.profilePhoto ??
                      'https://bmd.gov.bd/file/img/nwp/notfound.png'
                    }
                    name={fullName}
                    onClick={() => handleNavigation(item)}
                    handleAddToFevorite={() => setData(item)}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex justify-center">
            {profileData?.data?.count % 10 === 0 && (
              <button
                type="button"
                onClick={() => {
                  setPage(prev => prev + 1);
                }}
                className="text-white font-Onest bg-red-500   font-medium rounded-sm text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Load more
              </button>
            )}
          </div>
        </>
      )}
      {!isLoading && !isProfileListLoading && !isFetching && <Footer />}
    </>
  );
}
