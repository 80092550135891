import React from 'react';
import Header from '../components/Header';
import Footer from '../components/common/Footer';
import { suport } from '../config/constant';

const RefundAndReturnPolicy = () => {
  return (
    <>
      <Header isUserFullyOnboarded={false} />

      <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <strong>
          <h1>Refund and Return Policy</h1>
        </strong>
        <br />
        <p>
          At <strong>Runanubandh Vishwavivah</strong>, we strive to provide the
          best possible matrimonial services to help you find your life partner.
          Please read this policy carefully as it outlines the terms regarding
          refunds and returns.
        </p>
        <br />

        <h2>No Refunds or Returns</h2>

        <p>
          All payments made to <strong>Runanubandh Vishwavivah</strong> for
          premium membership plans or any other services are final and
          non-refundable. We do not offer refunds, exchanges, or returns for any
          payments or services, including in cases of dissatisfaction with the
          service or failure to find a match.
        </p>

        <br />

        <h2>Why We Have This Policy</h2>
        <p>
          As a digital platform offering matrimonial services, our services are
          considered intangible and irrevocable once accessed. The payment
          covers access to premium features and matchmaking services, which
          begin immediately upon payment. Therefore, it is not possible to issue
          refunds or returns after the service has been used.
        </p>

        <br />

        <h2>Subscription Cancellations</h2>
        <p>
          You can choose to cancel your subscription to premium services at any
          time. However, any payments already made will not be refunded, and you
          will continue to have access to premium features until the end of the
          billing period.
        </p>

        <br />

        <h2>Changes to This Policy</h2>
        <p>
          <strong>Runanubandh Vishwavivah</strong> reserves the right to modify
          or update this Refund and Return Policy at any time. Any changes will
          be posted on this page, and we encourage you to review this policy
          periodically to stay informed of any updates.
        </p>

        <br />

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this policy, feel free
          to contact our support team at <strong>{suport.email}</strong>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default RefundAndReturnPolicy;
