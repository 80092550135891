import React from 'react';
import Header from '../components/Header';
import Footer from '../components/common/Footer';

const TermsConditionScreen = () => {
  return (
    <>
      <Header isUserFullyOnboarded={false} />
      <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        <strong>
          <h6>Terms and Conditions</h6>
        </strong>

        <p>
          Welcome to <strong>Runanubandh Vishwavivah</strong> (
          <a href="https://runanubandhvishwavivah.com//">
            https://runanubandhvishwavivah.com/{' '}
          </a>
          ) is maintained by
          <strong> Runanubandh Vishwa Vivah LLP</strong>, By using our platform,
          you agree to comply with and be bound by the following terms and
          conditions of use. Please review the following terms carefully. If you
          do not agree to these terms, you should not use this platform.
        </p>
        <br />
        <br />
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using <strong>Runanubandh Vishwavivah</strong> (the
          "Platform"), you accept and agree to be bound by these Terms and
          Conditions (the "Agreement"). This Agreement is an electronic contract
          that establishes the legally binding terms you must accept to use the
          Platform and avail its services.
        </p>
        <br />

        <h2>2. Eligibility</h2>
        <ul>
          <li>
            You must be at least 18 years of age or the legal age of marriage
            under the laws of India.
          </li>
          <li>
            You must be unmarried, divorced, or legally separated and free to
            enter into a lawful marriage.
          </li>
          <li>
            The use of this Platform is restricted to individuals seeking a life
            partner for marriage.
          </li>
        </ul>
        <br />
        <h2>3. Registration and Account Security</h2>
        <ul>
          <li>
            You are responsible for maintaining the confidentiality of your
            account details, including your username and password.
          </li>
          <li>
            You agree to provide accurate, true, and up-to-date information
            during the registration process.
          </li>
          <li>
            You must not create an account for anyone other than yourself, and
            impersonation of any individual is strictly prohibited.
          </li>
        </ul>
        <br />
        <h2>4. User Conduct and Responsibilities</h2>
        <p>As a user of the Platform, you agree to:</p>
        <ul>
          <li>Use the Platform for matrimonial purposes only.</li>
          <li>
            Not engage in any unlawful activity, harassment, or abusive behavior
            toward other users.
          </li>
          <li>
            Refrain from uploading any inappropriate or offensive content,
            including but not limited to images, messages, or comments.
          </li>
          <li>
            Not solicit money or engage in financial transactions with other
            users.
          </li>
        </ul>
        <br />
        <h2>5. Termination of Service</h2>
        <ul>
          <li>
            <strong>Runanubandh Vishwavivah</strong> reserves the right to
            terminate or suspend your account without notice if you violate
            these Terms and Conditions or engage in inappropriate behavior.
          </li>
          <li>
            Users found guilty of fraudulent practices, harassment, or any form
            of illegal activity will have their accounts permanently
            deactivated.
          </li>
        </ul>
        <br />
        <h2>6. Fees and Payment</h2>
        <ul>
          <li>
            Some features of the Platform may require payment. You agree to pay
            any applicable fees associated with the services you choose to
            access.
          </li>
          <li>
            Fees paid for premium or enhanced services are non-refundable unless
            stated otherwise.
          </li>
        </ul>
        <br />
        <h2>7. Privacy Policy</h2>
        <p>
          Your personal information will be collected, stored, and processed in
          accordance with our Privacy Policy. By using the Platform, you agree
          that we may use your information as described in the Privacy Policy.
        </p>
        <br />
        <h2>8. Limitation of Liability</h2>
        <ul>
          <li>
            <strong>Runanubandh Vishwavivah</strong> is not responsible for any
            indirect, incidental, or consequential damages arising out of the
            use or inability to use the Platform.
          </li>
          <li>
            We are not responsible for any false or inaccurate information
            provided by users or for any disputes arising between users.
          </li>
          <li>
            The Platform is not responsible for the accuracy, validity, or
            outcome of any marriage decisions made based on the profiles
            available.
          </li>
        </ul>
        <br />
        <h2>9. Dispute Resolution</h2>
        <p>
          In case of any dispute arising from the use of the Platform, the
          matter will be governed by the laws of India, and the courts of Pune,
          Maharashtra, will have exclusive jurisdiction.
        </p>
        <br />
        <h2>10. Amendments to the Terms</h2>
        <p>
          <strong>Runanubandh Vishwavivah</strong> reserves the right to modify
          or change these Terms and Conditions at any time. It is your
          responsibility to review these terms periodically.
        </p>
        <br />
        <h2>11. Disclaimer</h2>
        <p>
          The Platform is provided "as-is" without any warranties of any kind.
          We do not guarantee any marriage or match-making success.
        </p>
        <br />
        <h2>12. Governing Law</h2>
        <p>
          This Agreement is governed by the laws of India without regard to its
          conflict of law provisions. You agree to submit to the personal and
          exclusive jurisdiction of the courts located within Pune, Maharashtra.
        </p>

        <p>
          By registering with <strong>Runanubandh Vishwavivah</strong>, you
          acknowledge that you have read, understood, and agreed to these Terms
          and Conditions.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditionScreen;
