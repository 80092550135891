import React, { useState } from 'react';
import { Avatar, Button, Dropdown, Modal, Navbar } from 'flowbite-react';
import ChangeLanguage from './common/ChangeLanguage/ChangeLanguage';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteUserProfileMutation,
  userInfo,
} from '../redux/services/userInfo/api';
import { useDispatch } from 'react-redux';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { showToast } from '../utils/ErrorToast';
interface propState {
  isUserFullyOnboarded?: boolean;
}

const Header = ({ isUserFullyOnboarded = false, ...props }: propState) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteProfile, { isLoading }] = useDeleteUserProfileMutation();
  const name = localStorage.getItem('name');
  const email = localStorage.getItem('email');
  const photo = localStorage.getItem('photo');
  const [openModal, setOpenModal] = useState(false);

  const handleLogout = () => {
    dispatch(userInfo.util.resetApiState());
    localStorage.clear();
    navigate('/', { replace: true });
  };

  const changePassword = () => {
    navigate('/change');
  };

  const UpdateProfile = () => {
    navigate('/updateProfile');
  };
  const UpdatePhoto = () => {
    navigate('/updatePhoto');
  };

  const AboutUs = () => {
    navigate('/about');
  };

  const ContactUs = () => {
    navigate('/contact');
  };

  const selectedTab = window.location.pathname.split('/');

  const handleDeleteAccount = () => {
    deleteProfile({})
      .then(res => {
        showToast('Account Deleted Successfully', 'success');
        setOpenModal(false);
        handleLogout();
      })
      .catch(error => {
        console.log({ error });
        showToast('Something went wrong', 'error');
      });
  };

  const openDeleteAccountModal = () => {
    setOpenModal(true);
  };

  return (
    <Navbar fluid rounded className="sticky top-0 z-30 shadow p-2">
      <Navbar.Brand
        className="sm:ml-7 ml-3 cursor-pointer"
        onClick={() => isUserFullyOnboarded && navigate('/home')}>
        <img
          src={require('../assets/logo2.png')}
          className="mr-3 h-16 sm:h-20"
          alt="Flowbite React Logo"
        />
      </Navbar.Brand>
      <>
        <div className="flex md:order-2 justify-center items-center sm:gap-2">
          <ChangeLanguage />

          {isUserFullyOnboarded && (
            <>
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <>
                    {photo && (
                      <Avatar
                        img={
                          photo ??
                          'https://bmd.gov.bd/file/img/nwp/notfound.png'
                        }
                        rounded
                        className="border border-gray-300 rounded-3xl"
                      />
                    )}
                  </>
                }>
                <Dropdown.Header>
                  <span className="block text-sm font-Onest">{name}</span>
                  <span className="block truncate text-xs font-medium font-Onest text-gray-500">
                    {email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item className="font-Onest" onClick={changePassword}>
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item className="font-Onest" onClick={UpdateProfile}>
                  Update Profile
                </Dropdown.Item>
                <Dropdown.Item className="font-Onest" onClick={UpdatePhoto}>
                  Update Photo
                </Dropdown.Item>
                <Dropdown.Item
                  className="font-Onest"
                  onClick={openDeleteAccountModal}>
                  Delete Account
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="font-Onest" onClick={handleLogout}>
                  Sign out
                </Dropdown.Item>
              </Dropdown>
              <Navbar.Toggle />
            </>
          )}
        </div>
        {isUserFullyOnboarded && (
          <Navbar.Collapse>
            <Navbar.Link
              className={`font-Onest text-${
                window.location.pathname.includes('/home') ? 'red' : 'gray'
              }-500 hover:font-bold cursor-pointer`}
              onClick={() => navigate('/home')}>
              Home
            </Navbar.Link>
            <Navbar.Link
              className={`font-Onest text-${
                window.location.pathname.includes('/about') ? 'red' : 'gray'
              }-500 hover:font-bold cursor-pointer`}
              onClick={AboutUs}>
              About Us
            </Navbar.Link>
            <Navbar.Link
              className={`font-Onest text-${
                window.location.pathname.includes('/favorite') ? 'red' : 'gray'
              }-500 hover:font-bold cursor-pointer`}
              onClick={() => navigate('/favorite')}>
              Favorite
            </Navbar.Link>
            <Navbar.Link
              className={`font-Onest text-${
                window.location.pathname.includes('/contact') ? 'red' : 'gray'
              }-500 hover:font-bold cursor-pointer`}
              onClick={ContactUs}>
              Contact Us
            </Navbar.Link>
          </Navbar.Collapse>
        )}
      </>

      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg  text-gray-500 dark:text-gray-600 font-Onest font-bold">
              Are you sure you want to delete your Account ?
            </h3>
            <p className="font-Onest text-sm mb-6 text-gray-500">
              Deleting your account is permanent and cannot be undone. This will
              result in the loss of all your data and access to our services.
            </p>
            <div className="flex justify-center gap-4">
              <Button
                className="bg-red-500 font-Onest"
                onClick={handleDeleteAccount}>
                {"Yes, I'm sure"}
              </Button>
              <Button
                color="gray"
                className="font-Onest"
                onClick={() => setOpenModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default Header;
